<template>
  <table class="EnterpriseRequestsTable table is-full-width is-striped is-hoverable">
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Organisation</th>
        <th>User Note</th>
        <th>Staff Note</th>
        <th class="is-narrow">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <enterprise-requests-table-row
        v-for="request in requests"
        :key="request.id"
        :request="request"
        @change="$emit('request-change', $event)"
      />
    </tbody>
  </table>
</template>

<script>
import EnterpriseRequestsTableRow from '@/components/staff/EnterpriseRequestsTableRow'

/**
 * Renders a table to manage enterprise requests
 * @module EnterpriseRequestsTable
 */
export default {
  name: 'EnterpriseRequestsTable',

  components: { EnterpriseRequestsTableRow },

  props: {
    /**
     * @type {HF_EnterpriseRequest[]}
     */
    requests: {
      type: Array,
      required: true
    }
  }
}
</script>
