<template>
  <div class="control">
    <basic-select
      v-model="status"
      :values="statuses"
      label-prop="label"
      value-prop="value"
      key-prop="value"
      class="EnterpriseRequestChangeStatusChange"
    />
  </div>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Renders the button to toggle between status changes.
 * @module EnterpriseRequestChangeStatusChange
 * @emits change
 */
export default {
  name: 'EnterpriseRequestChangeStatusChange',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      statuses: [{
        label: 'On Hold',
        value: 'on_hold'
      }, {
        label: 'In process',
        value: 'in_process'
      }, {
        label: 'Rejected',
        value: 'rejected'
      }, {
        label: 'Pending',
        value: 'pending'
      }, {
        label: 'Onboarded',
        value: 'onboarded'
      }]
    }
  },
  computed: {
    status: generateComputedSetterWithEmit()
  }
}
</script>
