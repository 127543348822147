<template>
  <tr
    v-loading="isLoading"
    :class="`EnterpriseRequestsTableRow--${request.status}`"
    class="EnterpriseRequestsTableRow"
  >
    <td class="is-narrow">
      {{ request.index }}
    </td>
    <td class="is-narrow">
      <div class="has-text-weight-bold">
        {{ request.name }}
      </div>
      <div>
        <a
          :href="`mailto:${request.email}`"
          class="has-text-primary"
        >
          {{ request.email }}
        </a>
      </div>
    </td>
    <td class="is-narrow">
      <div class="has-text-weight-bold">
        {{ request.organisation }}
      </div>
      <div class="has-text-grey">
        {{ request.phone }}
      </div>
    </td>
    <td>{{ request.message }}</td>
    <td class="is-width-1/4">
      <editable-field-extended
        :value="request.staffNotes"
        :placeholder="$t('pages.staff.enterprise_requests.staff_notes_placeholder')"
        :empty-text="$t('pages.staff.enterprise_requests.staff_notes_placeholder')"
        hide-label
        auto-stop-edit
        inline-placeholder
        control="EditableControlTextarea"
        @input="handleChange('staffNotes', $event)"
      />
    </td>
    <td class="is-width-1/6">
      <enterprise-request-change-status-change
        :value="request.status"
        @input="handleChange('status', $event)"
      />
    </td>
  </tr>
</template>

<script>
import { getEnterpriseRequestUpdateRequest } from '@/models/EnterpriseRequest'
import { BillingApiService } from '@/services/api/BillingApiService'
import EditableFieldExtended from '@hypefactors/app/src/components/forms/EditableFieldExtended'
import EnterpriseRequestChangeStatusChange from '@/components/staff/EnterpriseRequestChangeStatusChange'

/**
 * Renders the Enterprise table requests row
 * Allows changing enterprise request status and staff message
 * @module EnterpriseRequestsTableRow
 */
export default {
  name: 'EnterpriseRequestsTableRow',

  components: { EditableFieldExtended, EnterpriseRequestChangeStatusChange },

  props: {
    /**
     * @type {HF_EnterpriseRequest}
     */
    request: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    handleChange (field, value) {
      this.updateEnterpriseRequest({
        ...this.request,
        [field]: value
      })
    },
    async updateEnterpriseRequest (request) {
      try {
        this.isLoading = true
        await BillingApiService.updateEnterpriseRequest(request.id, getEnterpriseRequestUpdateRequest(request))
        this.$emit('change', request)
      } catch (e) {
        this.$displayRequestError(e, this.$t('errors.cannot_update_request'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.EnterpriseRequestsTableRow {
}
</style>
