<template>
  <div class="EnterpriseRequests">
    <heading>Manage Enterprise plan requests</heading>
    <enterprise-requests-table
      :requests="requestsList"
      @request-change="updateRequestStatus"
    />
  </div>
</template>

<script>
import { EnterpriseRequest } from '@/models/EnterpriseRequest'
import { BillingApiService } from '@/services/api/BillingApiService'
import EnterpriseRequestsTable from '@/components/staff/EnterpriseRequestsTable'

/**
 * Renders a page to manage User Enterprise Requests
 * @module EnterpriseRequests
 */
export default {
  name: 'EnterpriseRequests',
  components: { EnterpriseRequestsTable },
  data () {
    return {
      isLoading: false,
      /**
       * @type {HF_EnterpriseRequest[]}
       */
      requestsList: []
    }
  },

  mounted () {
    this.fetchEnterpriseRequests()
  },

  methods: {
    async fetchEnterpriseRequests () {
      try {
        this.isLoading = true
        const response = await BillingApiService.fetchEnterpriseRequests()
        this.requestsList = response.map(request => new EnterpriseRequest(request))
      } catch (error) {
        this.$displayRequestError(error, this.$t('errors.cannot_fetch_enterprise_requests'))
      } finally {
        this.isLoading = false
      }
    },
    updateRequestStatus (request) {
      const requestIndex = this.requestsList.findIndex(r => r.id === request.id)
      this.$set(this.requestsList, requestIndex, request)
    }
  }
}
</script>
