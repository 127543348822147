import Form from '@/services/forms/Form'
import { email, required, minLength } from 'vuelidate/lib/validators'

/**
 * Class representing a saved Enterprise Request
 * @alias HF_EnterpriseRequest
 */
export class EnterpriseRequest {
  constructor (request) {
    this.id = request.id
    this.name = request.name
    this.email = request.email
    this.phone = request.phone
    this.organisation = request.organisation
    this.message = request.message
    this.staffNotes = request.staff_notes
    this.status = request.status
    this.created_at = request.created_at
  }
}

/**
 * Constructs the Enterprise Request Object
 * @interface EnterpriseRequestFormObject
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 * @property {string} message
 * @property {string} organisation
 */

/**
 * @interface RequestEnterpriseForm
 * @extends EnterpriseRequestFormObject
 * @extends Form
 */

/**
 * Returns a new form instance for an EnterpriseRequest
 * @returns {RequestEnterpriseForm}
 */
export function requestEnterpriseForm () {
  return new Form({
    name: { value: '', rules: { required, min: minLength(5) } },
    email: { value: '', rules: { email, required } },
    phone: { value: '', rules: {} },
    message: { value: '', rules: {} },
    organisation: { value: '', rules: {} }
  })
}

/**
 * Extracts the usable fields from the User to be merged into the {@see RequestEnterpriseForm}
 * @param user
 * @returns {{phone: string, name: string, email: string}}
 */
export function getEnterpriseRequestDataFromUser (user) {
  return {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    phone: user.metadata.phone_number
  }
}

/**
 * Returns the EnterpriseRequest request object for updating
 * @param {HF_EnterpriseRequest} request
 */
export function getEnterpriseRequestUpdateRequest (request) {
  return {
    staff_notes: request.staffNotes,
    status: request.status
  }
}
